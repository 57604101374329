import DK_logo_blue from "../images/DK-Logo-2020-blue.svg";
import DK_flag from '../images/dkflag.svg'

const DKDobozos =   [
    {
        type: "rect",
        xPercent: 70,
        yPercent: 100,
        widthPercent: 70,
        heightPercent: 100,
        // fill: "#89b717",
        isRespectMargin: false,
        isSelectable: false,
        isDraggable: false,
        fillLinearGradientStartPoint: {  x: 0 },
        fillLinearGradientEndPoint: {  x: 100 },
        fillLinearGradientColorStops: [0, 'rgba(0,98,167,0)', 1, 'rgba(0,98,167,.8)'],
        rotation: 180
    },
    {
        type: "group",
        xPercent: 15,
        y: 20,
        widthPercent: 70,
        heightPercent: 50,
        layerAlign: 'middle',
        isRespectMargin: false,
        isSelectable: false,
        children: [
            {
                type: "rect",
                x: 0,
                y: 0,
                widthPercent: 100,
                heightPercent: 100,
                fill: "rgba(255,255,255,.8)",
                isRespectMargin: false,
                isSelectable: false,
                isDraggable: false,
            },
            {
                type: "label",
                xPercent: 0,
                height: 50,
                width: 330,
                y: 10,
                layerAlign: 'center',
                align: 'center',
                isRespectMargin: false,
                isSelectable: true,
                fontSize: 36,
                fontStyle: '900',
                lineHeight: 1.5,
                fontFamily: 'DK Raleway',
                text: "GRATULLÁLUNK!",
                textFill: '#c50068',
                labelFill: '',

            },
            {
                type: "label",
                xPercent: 0,
                height: 50,
                width: 500,
                y: 70,
                layerAlign: 'center',
                isRespectMargin: false,
                isSelectable: true,
                fontSize: 36,
                fontStyle: '600',
                lineHeight: 1.5,
                fontFamily: 'DK Raleway',
                text: "MA IS SIKERESEK VOLTUNK",
                align: 'center',
                textFill: 'rgba(0,98,167,1)',
                labelFill: '',
                growParent: true,
                marginTop: 100,
                marginBottom: 150
            },
            {
                type: "image",
                x: -20,
                yPercent: 70,
                widthPercent: 4,
                heightRatio: 11,
                layerAlign: 'bottom',
                image: DK_flag,
                isRespectMargin: false,
            },
            {
                type: "image",
                xPercent: 98,
                yPercent: 70,
                widthPercent: 4,
                heightRatio: 11,
                layerAlign: 'top',
                image: DK_flag,
                isRespectMargin: false,
            },
            {
                type: "image",
                xPercent: 25,
                yPercent: 100,
                layerAlign: 'bottom',
                widthPercent: 50,
                heightRatio: .238,
                isRespectMargin: false,
                image: DK_logo_blue
            },


        ]
    }


    ];


export default DKDobozos;