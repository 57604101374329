import DrKiss from "./dr-kiss";
import {Obuda01, Obuda02} from "./obuda";
import DKDobozos from "./DKDobozos";
import {setGeneralProperties} from "../utils/layerUtils";
import DKFelirat from "./DKFelirat";

const Layout = (props) => {
    let objects = [];

    switch (props.name) {
        case "DrKiss": objects = DrKiss; break;
        case "DKDobozos": objects = DKDobozos; break;
        case "DKFelirat": objects = DKFelirat; break;
        case "Obuda01": objects = Obuda01; break;
        case "Obuda02": objects = Obuda02; break;
        default: break;
    }

    if (!objects.length) {
        return null;
    }

    return setGeneralProperties(objects);
};

export default Layout;