import React from 'react';
import './cropper.css'

const Cropper = (props) => {
    return (
        <div ref={props.forwardedRef} className="cropper" style={props.styleHandler()}>
            
        </div>
    );
};

export default Cropper;