
import './App.css';
import Frame from "./components/UI/frame";

function App() {
  return (
    <Frame />
  );
}

export default App;

