import {Star, Text, Rect, Group, Label, Tag, Path, RegularPolygon} from 'react-konva';
import React from "react";
import ImageLayer from "../components/editor/imageLayer";
import {measureTextWidth, layerAlignment, cloneNestedArray} from "./layerUtils";


const generateLayers = (objRepo, stageWidth, stageHeight, stageScale, globalZoom, stageMargin, isSaving, handleDragStart, handleDragEnd, handleClick) => {
    return objRepo.map((object) => {
        const currentScale = isSaving === true ? 1 : stageScale;

        //generate position and sized based on the percent given at the first run

        if ('undefined' === typeof object.width) {
            // object.width    = object.widthPercent ? (object.widthPercent/100) * stageWidth : object.heightRatio ? ((object.widthPercent*object.heightRatio)/100) * stageHeight : object.width;
            object.width    = object.widthPercent ? (object.widthPercent/100) * stageWidth  : object.width;
        }

        if ('undefined' === typeof object.height) {
            object.height   = object.heightPercent ? (object.heightPercent/100) * stageHeight : object.heightRatio ? object.width*object.heightRatio : object.height;
        }

        if ('undefined' === typeof object.x) {
            object.x = (object.xPercent / 100) * stageWidth;
        }

        if ('undefined' === typeof object.y) {
            object.y = (object.yPercent / 100) * stageHeight;
        }
        // if ('undefined' === typeof object.y) {
        //     if ( object.align === 'bottom' ) {
        //         object.y = stageHeight-object.height;
        //     } else if( object.align === 'top' ) {
        //         object.y = 0;
        //     } else {
        //         object.y = (object.yPercent / 100) * stageHeight;
        //     }
        // }

        object = layerAlignment(object, stageWidth, stageHeight, stageMargin);

        // if ('undefined' === typeof object.x) {
        //     if ( object.align === 'center' ) {
        //         object.x = (stageWidth-object.width) / 2;
        //     } else if( object.align === 'left' ) {
        //         object.x = 0;
        //     } else if( object.align === 'right' ) {
        //         object.x =  stageWidth-object.width;
        //     } else {
        //         object.x = (object.xPercent / 100) * stageWidth;
        //     }
        // }

        const params = {
            ...object,
            key: object.id,
            id: object.id,
            x: object.x*currentScale,
            y: object.y*currentScale,
            fill: object.fill,
            draggable: object.isDraggable,
            onDragStart: (e) => handleDragStart(e),
            onDragEnd: (e) => handleDragEnd(e),
            onClick: (e) => handleClick(e),
            onTouchStart: (e) => handleDragStart(e),
            onTouchEnd: (e) => handleDragEnd(e),
            onTap: (e) => handleClick(e),

            shadowColor: "blue",
            shadowBlur: 5,
            shadowOpacity: object.isSelected && !isSaving ? 1 : 0
        };

        //test
        // params.stroke = 'black';
        // params.strokeWidth = 1;


        if (object.width) {
            params.width = Math.min(object.width*globalZoom, object.widthPercent ? (object.widthPercent/100) * stageWidth : stageWidth);
        }

        if (object.height && object.id !== '2_2') {
            params.height = Math.min(object.height*globalZoom, object.heightPercent ? (object.heightPercent/100) * stageWidth :stageHeight);
        }
        if (object.type === 'image') {
            console.log(object.heightPercent);

        }

        // if (object.yPercent) {
        //     params.y = Math.min(((object.yPercent*globalZoom) / 100) * stageHeight, stageHeight - params.height);
        //     params.y = params.y*currentScale;
        //
        // }

        params.width = params.width*currentScale;
        params.height = params.height*currentScale;


        // console.log(object.id, object.width, stageWidth)

        if (object.isRespectMargin) {

            if (object.isRespectMarginX !== false && params.x < stageMargin) {
                params.x = stageMargin*currentScale;
            }
            if (object.isRespectMarginX !== false && params.x + params.width > stageWidth - stageMargin) {
                params.x = (stageWidth - stageMargin - params.width)*currentScale;
            }
            if (object.isRespectMarginY !== false && params.y < stageMargin) {
                params.y = stageMargin*currentScale;
            }

            if (object.isRespectMarginY !== false && params.y + params.height > stageHeight - stageMargin) {
                params.y = (stageHeight - stageMargin - params.height)*currentScale;
            }
        }
        let lineNum = 0;
        switch (object.type) {
            case "star":
                return (
                    <Star
                        {...params}
                        numPoints={5}
                        innerRadius={20*currentScale}
                        outerRadius={40*currentScale}
                        fill="#89b717"
                    />
                )
            case "text":
                const textParams = cloneNestedArray(params);
                textParams.text = object.text;
                textParams.fontSize = object.fontSize*currentScale*globalZoom;
                lineNum = textParams.text.substr(0, textParams.text.selectionStart).split("\n").length;
                textParams.height = lineNum*(object.fontSize*3)*currentScale*globalZoom;

                return (
                    <Text
                        {...textParams}
                    />
                )
            case "rect":


                return (
                    <Rect
                        {...params}
                    />
                )
            case "polygon":


                return (
                    <RegularPolygon
                        {...params}
                    />
                )
            case "label":
                const labelTextParams = cloneNestedArray(params);
                labelTextParams.x = 0;
                labelTextParams.y = 0;
                labelTextParams.text = object.text;
                labelTextParams.fontSize = object.fontSize*currentScale*globalZoom;
                labelTextParams.key = labelTextParams.id + 'text';
                labelTextParams.id = labelTextParams.id + 'text';
                labelTextParams.draggable = false;

                if (params.textFill) {
                    labelTextParams.fill = params.textFill;
                }
                const labelWidth = measureTextWidth(labelTextParams.text, labelTextParams.fontFamily, labelTextParams.fontSize);
                labelTextParams.width = labelWidth;
                lineNum = labelTextParams.text.substr(0, labelTextParams.text.selectionStart).split("\n").length;
                labelTextParams.height = lineNum*(object.fontSize*2);


                const labelBgParams = cloneNestedArray(params);
                labelBgParams.key = labelBgParams.id + 'shape';
                labelBgParams.id = labelBgParams.id + 'shape';
                labelBgParams.y = 0;
                labelBgParams.x = 0-(5*currentScale);

                labelBgParams.width = labelWidth+(5*currentScale);
                labelBgParams.draggable = false;
                if (params.labelFill) {
                    labelBgParams.fill = params.labelFill;
                }


                if (object.labelShape) {
                    let path = object.labelShape;
                    const widthMinus = object.labelShape.match(/labelWidth-(\d{1,})/);
                    if (widthMinus && widthMinus[1]) {
                        path = path.replace(widthMinus[0], labelBgParams.width-parseInt(widthMinus[1]));
                    }

                    const heightMinus = object.labelShape.match(/labelHeight-(\d{1,})/);
                    if (heightMinus && heightMinus[1]) {
                        path = path.replace(heightMinus[0], labelBgParams.height-parseInt(heightMinus[1]));
                    }

                    path = path.replaceAll('labelWidth', labelBgParams.width);
                    labelBgParams.data = path.replaceAll('labelHeight', labelBgParams.height);

                    return (
                        <Label {...params} >
                            <Path {...labelBgParams} />
                            <Text text="hello" {...labelTextParams} />
                        </Label>
                    );

                }

                return (
                    <Label {...params} >
                        <Rect   {...labelBgParams} />
                        <Text  text="hello" {...labelTextParams} />
                    </Label>
                );
            case "tag":
                return (
                    <Tag
                        {...params}
                    />
                )
            case "image":
                let imageFile = object.image;

                if (!imageFile) {
                    return null;
                }
                if (object.id === 'bgimage') {
                    if (params.width) { params.width = object.width*currentScale }
                    if (params.height) { params.height = object.height*currentScale }
                } else {
                    if (params.width) { params.width = params.width*globalZoom }
                    if (params.height) { params.height = params.height*globalZoom }
                }


                if (typeof imageFile === "object" ) {
                    if (typeof imageFile.left !== 'undefined'
                        && stageWidth*stageScale/2 > (params.x + params.width/2)) {
                        imageFile = object.image.left;
                    }

                    if (typeof imageFile.right !== 'undefined'
                        && stageWidth*stageScale/2 < (params.x + params.width/2)) {
                        imageFile = object.image.right;
                    }
                }



                return (
                    <ImageLayer img={imageFile} {...params} />
                );
            case "group":
                let growLayer = object.children.filter((layer) => {
                    return layer.growParent;
                });

                //calculate height
                if (growLayer.length > 0) {
                    let growLayerLineNum = growLayer[0].text.substr(0, growLayer[0].text.selectionStart).split("\n").length;
                    params.height = ((growLayerLineNum*(growLayer[0].fontSize*1.5)) + growLayer[0].marginTop + growLayer[0].marginBottom) ;
                    object.height = params.height;
                    // console.log(growLayerLineNum*growLayer[0].fontSize);
                }


                return (
                    <Group {...params} >
                        {generateLayers(
                            object.children,
                            object.width,
                            object.height,
                            stageScale,
                            globalZoom,
                            stageMargin,
                            isSaving,
                            handleDragStart,
                            handleDragEnd,
                            handleClick
                        )}
                    </Group>
                );
            default: return false;
        }

    });
}

export default generateLayers;