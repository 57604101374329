
export const setGeneralProperties = (elements, parentIndex = null, isChild = false) => {

    if (!isChild && "undefined" !== typeof elements[0] && elements[0].id !== 'bgimage') {
        elements.unshift({
            id: 'bgimage',
            type: "image",
            x: 0,
            y: 0,
            widthPercent: 100,
            heightPercent: 100,
            isRespectMargin: false,
            image: null
        });
    }

    return elements.map((object, index) => {
        const currObject = {...object};

        if (object.labelShape) {
            currObject.labelShape = object.labelShape;
        }

        if (object.children) {
            currObject.children = setGeneralProperties(object.children, index, true);
        }

        return {
            ...currObject,
            id: 'undefined' !== typeof currObject.id ? currObject.id : (parentIndex ? parentIndex + '_' + index.toString() : index.toString()),
            align: 'undefined' !== typeof currObject.align ? currObject.align : true,
            isDraggable:        'undefined' !== typeof currObject.isDraggable ? currObject.isDraggable : true,
            isDragging:         false,
            isSelected:         false,
            isSelectable:       'undefined' !== typeof currObject.isSelectable ? currObject.isSelectable : true,
            isStickingLeft:     false,
            isStickingTop:      false,
            isStickingRight:    false,
            isStickingBottom:   false,
            isRespectMargin:    'undefined' !== typeof currObject.isRespectMargin ? currObject.isRespectMargin : true,
            isRespectMarginX:   'undefined' !== typeof currObject.isRespectMarginX ? currObject.isRespectMarginX : true,
            isRespectMarginY:   'undefined' !== typeof currObject.isRespectMarginY ? currObject.isRespectMarginY : true,

        }
    });
}

export const findLayerById = (id, layers) => {
    for (let index=0; index<layers.length; index++) {
        if (layers[index].children) {
            let found = findLayerById(id, layers[index].children)

            if(found) {
                return  found;
            }
        }

        if (layers[index].id === id) {
            return layers[index];
        }
    }
}

export const findLayerByProperty = (propertyName, value, layers) => {
    const output = [];
    for (let index=0; index<layers.length; index++) {
        if (layers[index].children) {
            let found = findLayerByProperty(propertyName, value, layers[index].children)

            for (let i = 0; i < found.length; i++) {
                output.push(found[i]);
            }
        }

        if (layers[index][propertyName] === value) {
            output.push(layers[index]);
        }
    }

    return output;
}

export const layerAlignment = (object, stageWidth, stageHeight, stageMargin) => {
    if ('undefined' !== typeof object.layerAlign) {
        switch (object.layerAlign) {
            case "bottom": object.y = stageHeight-object.height; break;
            case "top": object.y = stageMargin; break;
            case "middle": object.y = (stageHeight-object.height) / 2; break;
            case "center": object.x = (stageWidth-object.width) / 2;  break;
            case "left": object.x = stageMargin;break;
            case "right": object.x =  stageWidth-object.width-stageMargin; break;
            default: break;
        }
    }
    return object;
}

export const changeLayerProp = (id, layers, propName, value) => {
    for (let index=0; index<layers.length; index++) {
        if (layers[index].children) {
            layers[index].children = changeLayerProp(id, layers[index].children, propName, value)

        }

        if (layers[index].id === id) {
            if (Array.isArray(propName)) {
                for(let i=0; i<propName.length; i++) {
                    // if(layers[index].hasOwnProperty(propName[i])) {
                        layers[index][propName[i]] = value[i];
                    // }
                }
            } else {
                // if(layers[index].hasOwnProperty(propName)) {
                    layers[index][propName] = value;
                // }
            }

        }

    }
    return layers;
}

export const findSelectedLayer = (layers) => {
    for (let index=0; index<layers.length; index++) {
        if (layers[index].children) {
            let found = findSelectedLayer(layers[index].children)

            if(found) {
                return  found;
            }
        }

        if (layers[index].isSelected) {
            return layers[index];
        }
    }
}

export const cloneNestedArray = (data) => {
    return JSON.parse(JSON.stringify(data));
}

export const measureTextWidth = (text,fontface,fontSize) => {
    const c=document.createElement('canvas');
    const cctx=c.getContext('2d');
    cctx.font=fontSize+'px '+fontface;

    const lines = text.substr(0, text.selectionStart).split("\n");
    const textWidth=cctx.measureText(lines[0]).width;
    return textWidth+fontSize;

}

