import React, {useState, useEffect, useRef, Fragment} from 'react';

import FormRow from "./formRow";
// import {isMobile} from 'react-device-detect';

import useWindowSize from "../../utils/useWindowsSize";
import Cropper from "../editor/cropper";
import {Stage, Layer} from 'react-konva';


import generateLayers from "../../utils/generateLayers";
import {
    findLayerById,
    changeLayerProp,
    findSelectedLayer,
    cloneNestedArray,
    measureTextWidth,
    findLayerByProperty
} from '../../utils/layerUtils';
import layout from "../../layouts/layout";


const OUTPUT_STYLES = [
    { label: "free" },
    { label: "facebook share", width: 1200, height: 630},
    { label: "Instagram", width: 1080 , height: 1080 },
];
const OUTPUT_STYLES_SELECT = OUTPUT_STYLES.map( (style) => { return style.label });

const Frame = (props) => {
    const screenSize = useWindowSize();
    const [brand, setBrand] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [stageScale, setStageScale] = useState(1);
    const [stageWidth, setStageWidth] = useState(1000);
    const [outputStyle, setOutputStyle] = useState('');
    const [stageHeight, setStageHeight] = useState(500);
    const [stageMargin, setStageMargin] = useState(20);
    const [globalZoom, setGlobalZoom] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageRatio, setSelectedImageRatio] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const stageCanvasRef = useRef(null);
    const cropRef = useRef(null);
    const initialLayout = layout({'name': 'DrKiss'});
    const [objects, setObjects] = useState(cloneNestedArray(initialLayout));
    const stageRef = useRef(null);

    useEffect(() => {
        setObjects(layout({'name': brand}));
    }, [brand]);

    useEffect( () => {
        // The 'current' property contains info of the reference:
        // align, title, ... , width, height, etc.
        if(stageCanvasRef.current){
            // let height = stageCanvasRef.current.offsetHeight;
            // let height  = stageCanvasRef.current.offsetHeight;
            setWidth(stageCanvasRef.current.offsetWidth);
            setHeight(stageCanvasRef.current.offsetHeight);
            // setHeight(height);
        }

    }, [stageCanvasRef, screenSize]);

    useEffect( () => {

        if (selectedImage) {

            setObjects(objects => (
                objects.map((obj) => {
                    const modifications = {}
                    if (obj.id === 'bgimage') {
                        modifications.image = URL.createObjectURL(selectedImage);
                        modifications.heightRatio = selectedImageRatio;

                        if (selectedImageRatio < stageHeight/stageWidth) {
                            modifications.height   = stageHeight;
                            modifications.width = stageWidth * (1/selectedImageRatio)
                        } else {
                            modifications.height   = stageWidth*selectedImageRatio;
                            modifications.width   = stageWidth;
                        }
                    }

                    return {
                        ...obj,
                        ...modifications
                    };
                })
                )
            );
        }
    }, [selectedImage, selectedImageRatio, stageWidth, stageHeight]);


    const handleDragStart = (e) => {
        const id = e.target.id();
        // textRef.current.text(`current: ${id}`);
        setObjects(
            objects.map((star) => {
                return {
                    ...star,
                    isDragging: star.id === id,
                };
            })
        );
    };
    const handleDragEnd = (e) => {
        const id = e.target.id();
        let newX = e.target.x();
        let newY = e.target.y();

        if (id === 'bgimage') {
            if (newX > 0) { newX = 0; e.target.x(0) }
            if (newX + e.target.width() < stageWidth*stageScale) {
                newX = (stageWidth*stageScale)-e.target.width();
                e.target.x((stageWidth*stageScale)-e.target.width())
            }
            if (newY > 0) { newY = 0;  e.target.y(0) }
            if (newY + e.target.height() < stageHeight*stageScale) {
                newY = (stageHeight*stageScale)-e.target.height();
                e.target.y((stageHeight*stageScale)-e.target.height())
            }
        }

        // console.log(objects[2].children[4].y, e.target.y()*(1/stageScale), (1/stageScale));
        let newLayers = changeLayerProp(id, cloneNestedArray(objects),
            ['x', 'y', 'isDragging', 'layerAlign'],
            [newX*(1/stageScale), newY*(1/stageScale), false, null]);
        setObjects(newLayers);
    };


    const setSelectedLayer = (id) => {
        if (id === 'bgimage') {
            setObjects(
                objects.map((star) => {
                    return {
                        ...star,
                        isSelected: false,
                    };
                })
            );
            return false;
        }
        setObjects(
            objects.map((obj) => {
                if (obj.children) {
                    obj.children =  obj.children.map((objChild) => {
                        return {
                            ...objChild,
                            isSelected: objChild.id === id && objChild.isSelectable,
                        };
                    })
                }
                return {
                    ...obj,
                    isSelected: obj.id === id && obj.isSelectable,
                };
            })
        );
    }
    const handleClick = (e) => {
        let id = e.target.id();
        id = id.replace("text","")
        id = id.replace("shape","")
        setSelectedLayer(id);
    };

    const handleStageClick = (e) => {
        const emptySpace = e.target === e.target.getStage();
        if (!emptySpace) {
            return;
        }

        setObjects(
            objects.map((star) => {
                return {
                    ...star,
                    isSelected: false,
                };
            })
        );
    };

    function downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const saveImage = (x, y, width, height) => {
        setIsSaving(true);

        // const canvas = document.createElement('canvas');
        // canvas.width = width;
        // canvas.height = height;
        // canvas.getContext('2d').drawImage(stageRef.current.content.firstChild,x,y,width,height,0,0,width, height);
        // const uri  = canvas.toDataURL()
        // setStageScale(1);

        //clear selected
        // let layers = cloneNestedArray(objects);
        // const selected = findLayerByProperty('isSelected', true, layers);
        // console.log(selected);
        // for(let i=0; i<selected.length; i++) {
        //     layers = changeLayerProp(selected.id, layers, ['isSelected'], [false])
        // }
        // setObjects(layers);

        stageRef.current.width(stageWidth);
        stageRef.current.height(stageHeight);

        //wait till redraw
        //TODO: need a better solution
        setTimeout ( () => {
            const uri = stageRef.current.toDataURL();
            downloadURI(uri, 'stage.png');
            setIsSaving(false);
        }, 500)

    }

   const saveRedirectHandler = () => {
       // const currWidth = cropRef.current.offsetWidth;
       // const currHeight = cropRef.current.offsetHeight;
       // const x = cropRef.current.offsetLeft - width / 2;
       // const y = cropRef.current.offsetTop - height / 2;

       saveImage(0, 0, stageWidth, stageHeight);
   }

    const setSizeHandler = (option) => {

        if (option === 'free') {
            const layers = cloneNestedArray(objects);
            const layer = findLayerById('bgimage', layers);
            // console.log(layer.height, layer.height/layer.heightRatio)
            if (layer.image) {
                const img = new window.Image();
                // let objectUrl = URL.createObjectURL(layer.image);
                img.onload = function () {
                    setSelectedImageRatio(this.height / this.width)
                    setStageWidth(this.width);
                    setStageHeight(this.height);
                    const newLayers = changeLayerProp('bgimage', layers, ['x','y'], [0,0]);
                    setObjects(newLayers);
                    URL.revokeObjectURL(layer.image);
                };
                img.src = layer.image;

            } else {
                setStageWidth(1000);
                setStageHeight(1000);
            }

            setOutputStyle(option);
            setObjects(JSON.parse(JSON.stringify(layout({'name': brand}))));
            return ;
        }

        OUTPUT_STYLES.forEach( (style) => {
            if (option === style.label) {
                setStageWidth(style.width);
                setStageHeight(style.height);
                setOutputStyle(style.label);
            }
        } )
        setObjects(JSON.parse(JSON.stringify(layout({'name': brand}))));
    }

    const calculateSize = () => {
        let cropWidth  = stageWidth;
        let cropHeight = stageHeight;
        const horizScale = (width*.9) / cropWidth;
        const verticalScale = (height*.9) / cropHeight;

        setStageScale(Math.min(horizScale, verticalScale, 1));

        // if (stageRef.current) {
        //     stageRef.current.content.firstChild.style.transform = ` scale(${scale})`;
        // }
        if (stageRef.current) {
            // stageRef.current.content.firstChild.style.maxWidth = `100%`;
            stageRef.current.width(isSaving ? cropWidth : cropWidth*stageScale);
            stageRef.current.height(isSaving ? cropHeight : cropHeight*stageScale);
        }

        return {
            width: cropWidth + 'px',
            height: cropHeight + 'px',
            transform: `translate(-50%, -50%) scale(${stageScale})`
        }
    }

    const getLayerTextById = (id) => {
        const layer = findLayerById(id, cloneNestedArray(objects));

        if (layer.text) {
            return layer.text;
        }

        return '';
    }

    const changeLayerText = (id, text) => {
        const layers = cloneNestedArray(objects);
        const layer = findLayerById(id, layers);

        let newLayers = changeLayerProp(id, layers, 'text', text);

        if (layer.type === 'label') {
            const textWidth = measureTextWidth(layer.text, layer.fontFamily, layer.fontSize)
            newLayers = changeLayerProp(id, layers, 'width', textWidth);
        }

        setObjects(newLayers);
    }
    const layerEditForm = () => {

        const currObjects = cloneNestedArray(objects);
        const currLayer = objects ? findSelectedLayer(currObjects) : null;

        const textLayers = currObjects ? findLayerByProperty('type','text', currObjects) : [];
        const labelLayers = currObjects ? findLayerByProperty('type','label', currObjects) : [];

        const tl =  currObjects ? textLayers.concat(labelLayers).map((object) => {
            return { 'key': object.id, 'value': object.text.substring(0, 15) }
        }) : [];

        // const output = [];
        const output = [<FormRow
            key="1"
            type="dropdown"
            name="layer"
            label="Válassz layert"
            placeholder="Válassz a listából"
            data={tl}
            selected={currLayer ? currLayer.id : ''}
            forwardedOnClick={(selected) => {setSelectedLayer(selected)}}
        />];

        if (!currLayer) {
            return output;
        }

        if (currLayer.type === "text" || currLayer.type === "label") {
            output.push( (<FormRow type="textarea"
                                   key="2"
                            name="layerText"
                            label="Szöveg"
                            data={getLayerTextById(currLayer.id)}
                             // dataAttribs={[
                             //     {name: 'id', value: currLayer.id}
                             // ]}
                             dataAttribs={{
                                 'data-id': currLayer.id
                             }}
                            forwardedOnClick={(e) => {
                                changeLayerText(e.target.dataset.id, e.target.value);
                            }} />));
        }

        return output;
    }


    let content;


    if (screenSize.width < 768 && screenSize.height < 420) {
        content = <div className="screenLock" />
    } else {

        content = <div className="row mx-0">
            <div className="col-md-7 col-lg-8 col-xxl-9 workingPane d-flex align-items-center justify-content-center"
                 ref={stageCanvasRef}
            >
                <Cropper width={`${stageWidth}px`} height={`${stageHeight}px`} forwardedRef={cropRef} styleHandler={calculateSize} />
                <Stage width={stageWidth} height={stageHeight} ref={stageRef} onClick={handleStageClick} >
                    <Layer>
                        {/*<ImageLayer width={isSaving ? stageWidth : stageWidth*stageScale} height={isSaving ? stageWidth*selectedImageRatio : stageWidth*selectedImageRatio*stageScale}   ref={backgroundImageRef} img={selectedImage ? URL.createObjectURL(selectedImage) : null} draggable />*/}
                        {objects ? generateLayers(cloneNestedArray(objects), stageWidth, stageHeight, stageScale, globalZoom, stageMargin, isSaving, handleDragStart, handleDragEnd, handleClick) : null}
                    </Layer>
                </Stage>
                {/*<Stage width={1200} height={630}  ref={stageRef}
                       // onContentTouchstart={handleDragStart}
                       // onContentTouchmove={handleDragStart}
                       // onContentTouchend={handleDragEnd}
                       // onContentMousedown={handleMouseDown}
                       // onContentMousemove={handleMouseMove}
                       // onContentMouseup={handleMouseUp}

                >
                    <Layer>


                        {stars.map((star) => (
                            <Star
                                key={star.id}
                                id={star.id}
                                x={star.x}
                                y={star.y}
                                numPoints={5}
                                innerRadius={40}
                                outerRadius={80}
                                fill="#89b717"
                                // opacity={0.8}
                                draggable
                                // rotation={star.rotation}
                                // shadowColor="black"
                                // shadowBlur={10}
                                // shadowOpacity={0.6}
                                // shadowOffsetX={star.isDragging ? 10 : 5}
                                // shadowOffsetY={star.isDragging ? 10 : 5}
                                // scaleX={star.isDragging ? 1.2 : 1}
                                // scaleY={star.isDragging ? 1.2 : 1}

                                // onDragStart={handleDragStart}
                                // onDragEnd={handleDragEnd}
                                // onTouchStart={handleTouchStart}
                                // onTouchMove={handleTouchStart}
                                // onTouchEnd={handleTouchEnd}
                            />
                        ))}


                    </Layer>

                </Stage>*/}
            </div>
            <div className="col-md-5 col-lg-4 col-xxl-3 controlPane">

                {/*<div className="header d-flex align-items-center justify-content-end py-4">*/}
                {/*    <span className="me-3">Vissza a weboldalra</span><a href="/"  type="button" className="btn-close" aria-label="Close">Close</a>*/}
                {/*</div>*/}

                <div className="form pt-3 pb-5">
                    <p className="helperMessage">
                    </p>

                    <FormRow
                        type="dropdown"
                        name="outputType"
                        label="Válassz kinézetet"
                        placeholder="Válassz a listából"
                        data={['DrKiss', 'Obuda01', 'Obuda02', 'DKDobozos', 'DKFelirat']}
                        selected={brand}
                        forwardedOnClick={(data) => {setBrand(data)}}
                    />
                    { brand ? (<Fragment><div className="mb-3">
                    <input
                        type="file"
                        name="myImage"
                        className="form-control"
                        onChange={(event) => {
                            const img = new window.Image();
                            let objectUrl = URL.createObjectURL(event.target.files[0]);
                            img.onload = function () {
                                setSelectedImageRatio(this.height/this.width)
                                URL.revokeObjectURL(objectUrl);
                            };
                            img.src = objectUrl;
                            setSelectedImage(event.target.files[0]);
                        }}
                    /></div>

                    <FormRow
                        type="dropdown"
                        name="outputType"
                        label="Válassz kimenetet"
                        placeholder="Válassz a listából"
                        data={OUTPUT_STYLES_SELECT}
                        selected={outputStyle}
                        forwardedOnClick={setSizeHandler}
                    />

                    <FormRow
                        type="range"
                        name="margin"
                        className="w-100"
                        label="Margó mérete"
                        min="1" max="50" step="1" value={stageMargin}
                        onChange={(e) => {setStageMargin(e.target.value )}}
                    />

                    <FormRow
                        type="range"
                        name="zoom"
                        className="w-100"
                        label="Elemek globális nagyítása"
                        min="1" max="1.5" step="0.01" value={globalZoom}
                        onChange={(e) => {setGlobalZoom(e.target.value)}}
                    />

                    {layerEditForm()}

                    <button
                        className="btn btn-primary"
                        onClick={(e) => {e.preventDefault(); saveRedirectHandler();}}
                    >
                        Mentés
                    </button>
                    <a href="/"  className="d-inline-block ms-4 text-primary">Mégsem</a>
                    </Fragment>)
                    : (<p className="small text-center">Válassz kinézetet a továbblépéshez!</p>)}
                    <br />
                    <br />
                    <br />
                </div>

            </div>
        </div>;
    }

    const containerClasses = ["MainAppScreen", "container-fluid", "px-0"];

    // if (isMobile) {
    //     containerClasses.push("isMobile")
    // }

    return (
        <div className={containerClasses.join(" ")}  >
            {content}
        </div>
    );
};

export default Frame;