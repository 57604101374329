import DK_logo_white from "../images/DK-Logo-2020-white.svg";

const DKFelirat =   [
    {
        type: "label",
        xPercent: 0,
        height: 50,
        width: 330,
        y: 10,
        layerAlign: 'center',
        align: 'center',
        isRespectMargin: false,
        isSelectable: true,
        fontSize: 36,
        fontStyle: '900',
        lineHeight: 1.5,
        fontFamily: 'DK Raleway',
        text: "GRATULLÁLUNK!",
        textFill: '#c50068',
        labelFill: '#ffffff',
    },
    {
        type: "label",
        xPercent: 0,
        height: 50,
        width: 500,
        y: 70,
        layerAlign: 'center',
        align: 'center',
        isRespectMargin: false,
        isSelectable: true,
        fontSize: 36,
        fontStyle: '600',
        lineHeight: 1.5,
        fontFamily: 'DK Raleway',
        text: "MA IS SIKERESEK VOLTUNK",
        textFill: 'rgba(0,98,167,1)',
        labelFill: '#ffffff',
    },
        {
            type: "group",
            xPercent: 0,
            // yPercent: 70,
            widthPercent: 100,
            // heightPercent: 30,
            // y: 200,
            height: 250,
            layerAlign: 'bottom',
            isRespectMargin: false,
            isSelectable: false,
            children: [
                {
                    type: "rect",
                    x: 0,
                    y: 0,
                    widthPercent: 100,
                    heightPercent: 100,
                    // fill: "#89b717",
                    isRespectMargin: false,
                    isSelectable: false,
                    isDraggable: false,
                    fillLinearGradientStartPoint: {  y: 0 },
                    fillLinearGradientEndPoint: {  y: 100 },
                    fillLinearGradientColorStops: [0, 'rgba(0,98,167,0)', 1, 'rgba(0,98,167,1)']
                },
                {
                    type: "image",
                    xPercent: 25,
                    layerAlign: 'bottom',
                    widthPercent: 50,
                    heightRatio: .238,
                    image: DK_logo_white
                },


            ]
        }

    ];


export default DKFelirat;