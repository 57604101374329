import useImage from "use-image";
import {Image} from "react-konva";
import {forwardRef} from "react";

const ImageLayer = forwardRef((props, ref) => {
    const [image] = useImage(props.img);


       /* useEffect(() => {
            if (params.updateHandler) {
                params.updateHandler()
            }
        });*/



    return <Image ref={ref} {...props} image={image} />;

});

export default ImageLayer;