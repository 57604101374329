
import {useRef, useState, useEffect} from "react";


function useOutsideAlerter(ref, setDropDownOpen) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current
                && !ref.current.contains(event.target)
                // && ref.current.style.display === 'block'
                && !(event.target.dataset.toggle === 'dropdown' || event.target.parentElement.dataset.toggle === 'dropdown' )
        ) {
                setDropDownOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setDropDownOpen]);
}

const FormRow = (props) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const dropDownRef = useRef();
    let inputField;

    useOutsideAlerter(dropDownRef, setDropDownOpen);

    const dropDownClickHandler = (event) => {
        event.preventDefault();
        setDropDownOpen(!dropDownOpen);
    };



    let dataAttributes = {};
    if ('undefined' !== typeof props.dataAttribs) {
        dataAttributes = {...props.dataAttribs};
    }
    // if (props.dataAttribs && Array.isArray(props.dataAttribs)) {
    //     console.log(props)
    //     for (let i = 0; props.dataAttrib.length; i++) {
    //         dataAttributes['data-' + props.dataAttrib[i].name] = props.dataAttrib[i].value
    //     }
    // }

    switch (props.type) {
        case "text":
            inputField =<input type="text"
                name={props.name} className="input-text form-control"
                placeholder={props.placeholder}
                ref={props.forwardedRef}
                value={props.data}
                onChange={props.forwardedOnClick}
                {...dataAttributes}
            />;
            break;
        case "textarea":
            inputField =<textarea
                name={props.name} className="input-text form-control"
                placeholder={props.placeholder} rows="2" cols="5"
                ref={props.forwardedRef}
                onChange={props.forwardedOnClick}
                {...dataAttributes}
                value={props.data}
            />;
            break;
        case "flowerInput":
            if (props.forwardedOnClick) {
                inputField =<a
                    href="#/"
                    className="input-text form-control flowerInput"
                    onClick={() => { props.forwardedOnClick(props.data[0], props.data[1]); } }
                >
                    <img src={props.image} className={`${props.data[0]}-${props.data[1]}`} alt={`${props.data[0]}-${props.data[1]}`} />
                    <label>{props.data[0]}</label>
                    <span>{props.data[1]}</span>
                </a>;
            } else {
                inputField =<div
                    className="input-text form-control flowerInput disabled"
                >
                    <img src={props.image} className={`${props.data[0]}-${props.data[1]}`} alt={`${props.data[0]}-${props.data[1]}`} />
                    <label>{props.data[0]}</label>
                    <span>{props.data[1]}</span>
                </div>;
            }


            break;
        case "dropdown":

            let selectedData =
            props.data.filter((data) => {
                return "object" === typeof data && data.key === props.selected;
            });


            inputField =
            //     <div className="btn-group  w-100">
            //     <a href="#/" type="button" className="input-text form-control dropdown-toggle" data-toggle="dropdown"
            //        onClick={(event) => dropDownClickHandler(event)}
            //     >
            //         <span className="d-inline-block w-100">{props.selected ?
            //             selectedData.length > 0 ? selectedData[0].value : props.selected :
            //             props.placeholder}</span>
            //     </a>
            //
            //     <ul className="dropdown-menu" ref={dropDownRef} style={{display: dropDownOpen ? 'block' : 'none'}}>
            //         {props.data.map((data, index) => {
            //             return ( <li key={index}
            //                             onClick={() => { props.forwardedOnClick( "object" === typeof data ? data.key : data);  setDropDownOpen(false);} }
            //             >
            //                 <a href="#/" title="">{"object" === typeof data ? data.value : data}</a>
            //             </li>);
            //         })}
            //     </ul>
            // </div>
            <select className="form-select" ref={dropDownRef} value={selectedData.length > 0 ? selectedData[0].key : props.selected}
                    onChange={(e) => { props.forwardedOnClick( e.target.value);  } }
            >
                <option>{props.placeholder}</option>
                {props.data.map((data, index) => {
                                return ( <option key={index}
                                                 value={"object" === typeof data ? data.key : data}
                                >
                                    {"object" === typeof data ? data.value : data}
                                </option>);
                            })}
            </select>;

            break;
        case "dropdownImage":

            const items = [];
            if (Array.isArray(props.data)) {

                Object.keys(props.data).forEach(function(key) {
                    let image = null;

                    if (typeof props.images !== 'undefined') {
                        if (typeof props.images[props.data[key]] === 'object') {
                            image = props.images[props.data[key]];
                        }

                    }
                        items.push( <li key={props.data[key]}
                                        onClick={() => { props.forwardedOnClick( props.data[key]);  setDropDownOpen(false);} }
                        >
                            <a href="#/" title="Virág kiválasztása">{image}{props.data[key]}</a>
                        </li>);
                });
            } else if (typeof props.data === "object" ) {
                Object.keys(props.data).forEach(function(key, index) {

                    if (Array.isArray(props.data[key])) {
                        items.push( <li key={key} className="dropdown-header">{key}</li>);

                        Object.keys(props.data[key]).forEach(function(i, itemIndex) {
                            items.push( <li
                                key={itemIndex}
                                onClick={() => { props.forwardedOnClick(key, props.data[key][i]);  setDropDownOpen(false); } }
                            >
                                <a href="#/" title="Virág kiválasztása" ><img src={props.images[key]} className={` ${key}-${props.data[key][i]}`} alt={`${key} ${props.data[key][i]}`} />{props.data[key][i]}</a>
                            </li>);
                        });

                    }
                });
            }

            inputField = <div className="btn-group  w-100">
                <a href="#/" type="button" className="input-text form-control dropdown-toggle" data-toggle="dropdown"
                        onClick={(event) => dropDownClickHandler(event)}
                >
                    <span className="d-inline-block w-100">{props.selected ? props.selected :  props.placeholder}</span>

                </a>

                <ul className="dropdown-menu" ref={dropDownRef} style={{display: dropDownOpen ? 'block' : 'none'}}>
                    {items}
                </ul>


            </div>;
            break;
        case "range":
            inputField = <input
                {...props}
                ref={props.forwardedRef}
            />;
            break;
        case "hidden":
            inputField = <input
                type={props.type}
                name={props.name}
                ref={props.forwardedRef}
                value={props.value}
            />;
            break;
        default:
            inputField = <input
                type={props.type}
                name={props.name}
                className="input-text form-control"
                placeholder={props.placeholder}
                ref={props.forwardedRef}
                onChange={props.forwardedOnClick}
            />
    }

    if (props.type === 'hidden') {
        return inputField;
    }

    let note = null;
    if (props.note) {
        note = <span className="small ps-3">{props.note}</span>;
    }

    return (
        <div className={`mb-3 my-field-class form-row-wide ${props.type}-container`} >
                <label
                    htmlFor={props.name} className="">{props.label}</label>
                    {inputField}
                    {note}
        </div>
    );
};

export default FormRow;