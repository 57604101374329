import obuda_logo from "../images/obudalogo.png";
import obuda_visual from "../images/obudavisual.svg";

export const Obuda01 =   [
        {
            type: "group",
            xPercent: 0,
            widthPercent: 100,
            height: 150,
            layerAlign: 'bottom',
            isRespectMargin: false,
            isSelectable: false,
            children: [
                {
                    type: "polygon",
                    xPercent: 100,
                    y: 35,
                    width: 100,
                    height: 100,
                    heightPercent: 100,
                    fill: "#926886",
                    isRespectMargin: false,
                    isSelectable: false,
                    isDraggable: false,
                    sides: 3,
                    radius: 30,
                    scaleX: 1.7
                },
                {
                    type: "rect",
                    x: 0,
                    layerAlign: 'bottom',
                    widthPercent: 100,
                    heightPercent: 75,
                    isRespectMargin: false,
                    isSelectable: false,
                    isDraggable: false,
                    fillLinearGradientStartPoint: {  y: 0 },
                    fillLinearGradientEndPoint: {  y: 150 },
                    fillLinearGradientColorStops: [0, '#926886', 1, '#684a60']
                },
                {
                    type: "image",
                    xPercent: 40,
                    yPercent: -10,
                    width: 300,
                    height: 300,
                    heightPercent: 200,
                    isRespectMargin: false,
                    image: obuda_visual
                },
                {
                    type: "text",
                    x: 0,
                    y: -30,
                    fill: "#ffffff",
                    height: 20,
                    widthPercent: 90,
                    fontSize: 65,
                    fontFamily: 'Montserrat',
                    text: "KÖSZÖNÖM,",
                    fontStyle: '900',
                    isRespectMarginY: false,
                },
                {
                    type: "text",
                    x: 0,
                    y: 60,
                    widthPercent: 40,
                    height: 200,
                    isRespectMarginY: false,
                    fill: "#ffffff",
                    fontSize: 34,
                    fontStyle: '600',
                    lineHeight: 1,
                    fontFamily: 'Montserrat',
                    text: 'hogy a III. kerületért\ndolgozhatok.'

                },

                {
                    type: "image",
                    xPercent: 77,
                    yPercent: 42,
                    widthPercent: 13,
                    heightRatio: 0.488,
                    image: obuda_logo,
                },

            ]
        }

    ];


export const Obuda02 =   [

    {
        type: "group",
        xPercent: 0,
        widthPercent: 100,
        height: 250,
        layerAlign: 'bottom',
        isRespectMargin: false,
        isSelectable: false,
        children: [
            {
                type: "polygon",
                xPercent: 100,
                y: 75,
                width: 100,
                height: 100,
                heightPercent: 100,
                fill: "#727b95",
                isRespectMargin: false,
                isSelectable: false,
                isDraggable: false,
                sides: 3,
                radius: 30,
                scaleX: 1.7
            },
            {
                type: "rect",
                x: 0,
                layerAlign: 'bottom',
                widthPercent: 100,
                heightPercent: 65,
                // fill: "#89b717",
                isRespectMargin: false,
                isSelectable: false,
                isDraggable: false,
                fillLinearGradientStartPoint: {  y: 0 },
                fillLinearGradientEndPoint: {  y: 150 },
                fillLinearGradientColorStops: [0, '#727b95', 1, '#484e5e']
            },
            {
                type: "image",
                xPercent: 2,
                yPercent: 0,
                width: 350,
                height: 350,
                heightPercent: 200,
                isRespectMargin: false,
                image: obuda_visual
            },
            {
                type: "text",
                x: 0,
                y: -90,
                fill: "#ffffff",
                height: 20,
                widthPercent: 90,
                fontSize: 65,
                fontFamily: 'Montserrat',
                text: "KÖSZÖNÖM,",
                fontStyle: '900',
                isRespectMarginY: false,
            },
            {
                type: "text",
                x: 0,
                y: -20,
                widthPercent: 40,
                height: 200,
                isRespectMarginY: false,
                fill: "#ffffff",
                fontSize: 34,
                fontStyle: '600',
                lineHeight: 1,
                fontFamily: 'Montserrat',
                text: 'hogy a III. kerületért\ndolgozhatok.'
            },

            {
                type: "image",
                xPercent: 75,
                yPercent: 50,
                widthPercent: 17,
                heightRatio: 0.488,
                image: obuda_logo,

            },

        ]
    }

];