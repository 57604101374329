import dr_kiss_logo from "../images/dr_kiss_logo.svg";
import dr_kiss_logo_right from "../images/dr_kiss_logo-right.svg";
import dr_kiss_pipa from "../images/dr_kiss_pipa.svg";

const DrKiss =   [
        {
            type: "image",
            x: 0,
            y: 0,
            widthPercent: 20,
            heightRatio: 0.3934,
            // heightRatio: 0.75,
            image: {
                left: dr_kiss_logo,
                right: dr_kiss_logo_right
            }
        },
        {
            type: "group",
            xPercent: 0,
            // yPercent: 70,
            widthPercent: 100,
            // heightPercent: 30,
            // y: 200,
            height: 200,
            layerAlign: 'bottom',
            isRespectMargin: false,
            isSelectable: false,
            children: [{
                type: "rect",
                x: 0,
                y: 0,
                widthPercent: 100,
                heightPercent: 100,
                // fill: "#89b717",
                isRespectMargin: false,
                isSelectable: false,
                isDraggable: false,
                fillLinearGradientStartPoint: {  y: 0 },
                fillLinearGradientEndPoint: {  y: 250 },
                fillLinearGradientColorStops: [0, 'rgba(0,0,0,0)', 1, 'rgba(0,0,0,.5)']
            },
                {
                    type: "text",
                    x: 0,
                    y: 0,
                    fill: "#ffffff",
                    height: 54,
                    widthPercent: 90,
                    fontSize: 54,
                    fontFamily: 'Alfa Slab One',
                    text: "Minden cselekedett",
                    isRespectMarginY: false,
                },
                {
                    type: "text",
                    x: 0,
                    y: 60,
                    widthPercent: 40,
                    height: 200,
                    // heightRatio: 1.85,
                    // heightPercent: 75,
                    isRespectMarginY: false,
                    fill: "#ffffff",
                    fontSize: 24,
                    fontStyle: '600',
                    lineHeight: 1.5,
                    fontFamily: 'Montserrat',
                    text: 'MÖGÖTT LEGYEN EGY\nFENNTARTHATÓSÁGI\nGONOLAT!'

                },
                {
                    type: "image",
                    xPercent: 45,
                    yPercent: 35,
                    widthPercent: 10,
                    heightRatio: 0.7889,
                    isRespectMargin: false,
                    image: dr_kiss_pipa
                },

                {
                    type: "label",
                    xPercent: 60,
                    height: 30,
                    width: 20,
                    y: 60,
                    isRespectMargin: false,
                    isSelectable: true,
                    fontSize: 22,
                    fontStyle: '900',
                    lineHeight: 1.5,
                    fontFamily: 'Montserrat',
                    text: "#ZÖLD",
                    textFill: 'white',
                    labelFill: '#00b9a2',
                    labelShape: 'M 0 0 L labelWidth 0 L labelWidth labelHeight-10 L labelWidth-9 labelHeight L 0 labelHeight',
                },

                {
                    type: "label",
                    xPercent: 60,
                    height: 30,
                    width: 20,
                    y: 105,
                    isRespectMargin: false,
                    isSelectable: true,
                    fontSize: 22,
                    fontStyle: '900',
                    lineHeight: 1.5,
                    fontFamily: 'Montserrat',
                    text: "#DEMOKRATA",
                    textFill: 'black',
                    labelFill: '#00b9a2',
                    labelShape: 'M 0 0 L labelWidth 0 L labelWidth labelHeight-10 L labelWidth-9 labelHeight L 0 labelHeight',
                }
            ]
        }

    ];


export default DrKiss;